import type { AppProps } from 'next/app'
import Router from 'next/router'
import React, { useEffect } from 'react'
import NProgress from 'nprogress'
import 'lazysizes'

import '@/styles/main.scss'
import { fontGalano } from '@/app/fonts'

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    const handleRouteStart = () => {
      NProgress.start()
    }

    const handleRouteComplete = () => {
      NProgress.done()
    }

    Router.events.on('routeChangeStart', handleRouteStart)
    Router.events.on('routeChangeComplete', handleRouteComplete)

    return function cleanup() {
      Router.events.off('routeChangeStart', handleRouteStart)
      Router.events.off('routeChangeComplete', handleRouteComplete)
    }
  }, [])

  return (
    <>
      <main className={fontGalano.className}>
        <Component {...pageProps} />
      </main>
    </>
  )
}

export default MyApp
